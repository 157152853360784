import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchHeaders } from "../../utils/TokenUtils";

const PromptCRUD = ({ user }) => {
  const [prompts, setPrompts] = useState([]);
  const [currentOperation, setCurrentOperation] = useState("");
  const [newPrompt, setNewPrompt] = useState({
    id: "",
    prompt: "",
    category: "",
    tag: "",
    output_format: "",
    degree: "",
    specialization: "",
    chunk_size: "",
    status: "",
  });
  const [isAdmin, setIsAdmin] = useState(false)

  const onFormChange = (e) => {
    setNewPrompt({
      ...newPrompt,
      [e.target.name]: e.target.value,
    });
  };

  const resetNewPrompt = () => {
    setNewPrompt({
      prompt: "",
      category: "",
      tag: "",
      output_format: "",
      degree: "",
      specialization: "",
      chunk_size: "",
      status: "",
    });
  };

  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: true,
      icon: false,
    });
  };

  const showErrorMessage = (message) => {
    toast.error(message, {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: true,
      icon: false,
    });
  };

  const addorUpdatePrompt = async (prompt_operation) => {
    try {
      const params = {
        operation: prompt_operation,
        data: {
          prompt: newPrompt.prompt,
          category: newPrompt.category,
          tags: newPrompt.tag,
          output_format: newPrompt.output_format,
          degree_specific:
            newPrompt.degree === "true" || newPrompt.degree === true,
          specialization_specific:
            newPrompt.specialization === "true" ||
            newPrompt.specialization === true,
          num_chunks: newPrompt.chunk_size,
          status: newPrompt.status === "true" || newPrompt.status === true,
        },
        prompt_id: prompt_operation === "UPDATE" ? newPrompt.id : "",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/prompt-crud`,
        params,
        {
          headers: fetchHeaders(),
        }
      );
      if (res && res.data) {
        if (prompt_operation === "CREATE") {
          const tempPrompts = [...prompts];
          newPrompt.id = res.data["_id"];
          setPrompts([...tempPrompts, newPrompt]);
          showSuccessMessage("Successfully added new prompt !");
        } else {
          let tempPrompts = [...prompts];
          tempPrompts = tempPrompts.filter((p) => p.id !== newPrompt.id);
          setPrompts([...tempPrompts, newPrompt]);
          showSuccessMessage("Successfully updated prompt !");
        }
      } else {
        showErrorMessage(`Something went wrong.`);
      }
    } catch (e) {
      console.log("Error while adding prompt", e);
      showErrorMessage(`Error while ${prompt_operation} prompt, Error: ${e}`);
    }
  };

  const updatePrompts = (operation) => {
    if (operation === "Add") {
      addorUpdatePrompt("CREATE");
    } else if (operation === "Update") {
      addorUpdatePrompt("UPDATE");
    } else {
      deletePrompt(newPrompt);
    }

    resetNewPrompt();
    setCurrentOperation("");
  };

  const updatePrompt = (prompt, operation) => {
    setCurrentOperation(operation);
    setNewPrompt(prompt);
  };

  const deletePrompt = async (prompt) => {
    try {
      const params = {
        operation: "DELETE",
        prompt_id: prompt.id,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/prompt-crud`,
        params,
        {
          headers: fetchHeaders(),
        }
      );
      if (res && res.data) {
        const tempPrompts = [...prompts];
        setPrompts(tempPrompts.filter((p) => p.id !== prompt.id));
        showSuccessMessage("Successfully deleted prompt !");
      } else {
        showErrorMessage(`Something went wrong.`);
      }
    } catch (e) {
      console.log("Error deleting prompt", e);
      showErrorMessage(`Error while deleting prompt, Error: ${e}`);
    }
  };

  useEffect(() => {
    const user_details = JSON.parse(user)
    setIsAdmin(user_details.is_admin)
    if(user_details.is_admin){
      fetchAllPrompts();
    }
  }, []);

  const shortenString = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
  };

  const fetchAllPrompts = async () => {
    try {
      const params = {
        operation: "VIEW",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/prompt-crud`,
        params,
        {
          headers: fetchHeaders(),
        }
      );
      if (res && res.data) {
        const tempPrompts = [];
        for (const prompt of res.data) {
          let obj = {
            id: prompt.id,
            prompt: prompt.prompt,
            category: prompt.category,
            tag: prompt.tags,
            output_format: prompt.output_format,
            degree: prompt.degree_specific,
            specialization: prompt.specialization_specific,
            chunk_size: prompt.num_chunks,
            status: prompt.status,
          };
          tempPrompts.push(obj);
        }
        setPrompts(tempPrompts);
      }
    } catch (e) {
      console.log("Error while fetching all prompts", e);
    }
  };

  if (!isAdmin) {
    return <>
      <div className="container">
      <div className="row mt-3">
          <h1 className="h2">
          <i class="fa-solid fa-triangle-exclamation me-3" style={{
            color: '#D2042D'
          }}></i>
            <strong style={{
              color: '#D2042D'
            }}>Not Authorized!</strong>
          </h1>
      </div>
    </div>
    </>
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-6">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => setCurrentOperation("Add")}
            >
              Add Prompt
            </button>

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      {currentOperation} prompt
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={resetNewPrompt}
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="form">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Prompt:-{" "}
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Enter Prompt .."
                          name="prompt"
                          value={newPrompt.prompt}
                          rows={5}
                          onChange={(e) => {
                            onFormChange(e);
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Category:-{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Category .."
                          name="category"
                          value={newPrompt.category}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Tag:-{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Tag .."
                          name="tag"
                          value={newPrompt.tag}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Output Format:-{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Output Format .."
                          name="output_format"
                          value={newPrompt.output_format}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Degree Specific Prompt?
                        </label>
                        <select
                          name="degree"
                          value={newPrompt.degree}
                          className="form-control"
                          onChange={(e) => onFormChange(e)}
                        >
                          <option value="">Is degree specific?</option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Specialization Specific Prompt?
                        </label>
                        <select
                          name="specialization"
                          className="form-control"
                          value={newPrompt.specialization}
                          onChange={(e) => onFormChange(e)}
                        >
                          <option value="">Is specialization specific?</option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Chunk Size:-{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Chunk Size .."
                          name="chunk_size"
                          value={newPrompt.chunk_size}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Prompt Status?
                        </label>
                        <select
                          name="status"
                          className="form-control"
                          value={newPrompt.status}
                          onChange={(e) => onFormChange(e)}
                        >
                          <option value="">Prompt Status ?</option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => updatePrompts(currentOperation)}
                      data-bs-dismiss="modal"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              class="modal fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel2">
                      Delete Prompt!
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={resetNewPrompt}
                    ></button>
                  </div>
                  <div class="modal-body">
                    <p>
                      This Prompt will be permanently{" "}
                      <span className="fw-bold">deleted</span> from the
                      database.
                    </p>
                    <p>
                      Do You really want to{" "}
                      <span className="fw-bold">delete this prompt?</span>
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={() => updatePrompts(currentOperation)}
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="prompt-table mb-4">
              <table className="table table-striped">
                <thead>
                  <th>Sno</th>
                  <th>Prompt</th>
                  <th>Category</th>
                  <th>Tag</th>
                  <th>Output Format</th>
                  <th>Degree</th>
                  <th>Specialization</th>
                  <th>Chunk</th>
                  <th>Status</th>
                  <th>Operation</th>
                </thead>
                <tbody>
                  {prompts.length > 0 &&
                    prompts.map((prompt, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{shortenString(prompt.prompt, 120)}</td>
                          <td>{shortenString(prompt.category, 25)}</td>
                          <td>{shortenString(prompt.tag, 15)}</td>
                          <td>{shortenString(prompt.output_format, 20)}</td>
                          <td>
                            {prompt.degree === true || prompt.degree === "true"
                              ? "True"
                              : "False"}
                          </td>
                          <td>
                            {prompt.specialization === true ||
                            prompt.specialization === "true"
                              ? "True"
                              : "False"}
                          </td>
                          <td>{prompt.chunk_size}</td>
                          <td>
                            {prompt.status === true || prompt.status === "true"
                              ? "True"
                              : "False"}
                          </td>
                          <td
                            style={{
                              width: "120px",
                            }}
                          >
                            <div
                              className="btn btn-success "
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => updatePrompt(prompt, "Update")}
                            >
                              <i class="fa-regular fa-pen-to-square"></i>
                            </div>
                            <div
                              className="btn btn-danger ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                              onClick={() => updatePrompt(prompt, "Delete")}
                            >
                              <i class="fa-solid fa-trash"></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromptCRUD;
