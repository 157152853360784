import React, { useState, useRef, useEffect } from "react";
import "./ChatBox.css";
import Loading from "../dashboard_mvp/Loading";
import axios from "axios";
import { fetchHeaders } from "../../utils/TokenUtils";

const ChatBox = ({ data, setData, setChunks_found }) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat box when messages change
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    if (userInput.trim() === "") {
      return;
    }

    const newMessages = [...messages, { text: userInput, sender: "user" }];

    setMessages(newMessages);
    setUserInput("");
    setLoading(true);

    const { llm, sys_prompt } = data;

    const params = {
      institute_id: Number(data.institute_id),
      prompt: data.prompt,
    };

    if (llm) {
      params.llm = llm;
    }

    if (sys_prompt) {
      params.sys_prompt = sys_prompt;
    }

    const res = await axios.post(
      `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/query-prompt`,
      params,
      {
        headers: fetchHeaders(),
      }
    );
    if (res && res.data) {
      console.log(res.data);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: res.data.answer_data, sender: "bot" },
      ]);
      setChunks_found(res.data.chunks_found);
    }

    setLoading(false);
  };

  return (
    <div className="chatbot-container">
      <div className="chat-box" id="chat-box" ref={chatBoxRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${
              message.sender === "user" ? "user-message" : "bot-message"
            }`}
          >
            {message.text.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
      {loading && (
        <div className="ms-2 mb-1">
          <Loading />
        </div>
      )}
      <div className="input-container">
        <input
          type="text"
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value);
            setData({
              ...data,
              prompt: e.target.value,
            });
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") sendMessage();
          }}
          placeholder="Enter Your Prompt Here..."
        />
        <button onClick={sendMessage}>
          <span className="me-2">Send</span>
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
