import React, { useEffect, useState } from "react";

import img8 from "../../assets/images/button-icon.svg";
import SaarthiInternal from "./SaarthiInternal";
import axios from "axios";
import Carousel from "react-multi-carousel";
import { saarthi_constants } from "../../json/saarthi_constant";

import "react-multi-carousel/lib/styles.css";
import "./Saarthi.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const defaultInstitutes = [36342, 33544, 3034];

const Saarthi = ({ gpt_4o_mini }) => {
  const [change, setChange] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [sessionId, setSessionId] = useState("");

  const sendMessage = async (text) => {
    if (text.trim() === "") {
      return;
    }

    const newMessages = [...messages, { text: text, sender: "user" }];

    setMessages(newMessages);
    setUserInput("");
    setLoading(true);

    // API CALL
    const params = {
      messages: [
        {
          role: "user",
          content: text,
        },
      ],
    };

    try {
      let url = gpt_4o_mini ? `${process.env.REACT_APP_SAARTHI_SERVER_URL}/chat-mini` : `${process.env.REACT_APP_SAARTHI_SERVER_URL}/chat`;
      const res = await axios.post(`${url}`, params, {
        headers: {
          "X-Session-ID": sessionId,
          "Content-Type": "application/json",
        },
      });

      if (res && res.data) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: res.data.response, sender: "bot" },
        ]);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setErrorMsg("An error occurred while sending the message.");
    } finally {
      setLoading(false);
    }
  };
  const onSelectPreDefinedQuery = (text) => {
    setUserInput(text);
    sendMessage(text);
    setChange(true);
  };

  const generateSessionId = () => {
    const randomId =
      Math.random().toString(36).substr(2, 9) + Date.now().toString(36);
    setSessionId(randomId);
  };

  useEffect(() => {
    generateSessionId();
  }, []);

  const handleKeyChange = (e) => {
    const messageToShowTemp = messages.filter(
      (message) => message.sender === "user"
    );

    if (messageToShowTemp.length === 0) {
      return;
    }

    if (e.key === "ArrowUp") {
      if (!userInput) {
        setUserInput(messageToShowTemp[messageToShowTemp.length - 1].text);
      } else {
        const textIndex = messageToShowTemp.findIndex(
          (message) => message.text === userInput
        );
        if (textIndex === 0) {
          setUserInput(userInput);
        } else if (textIndex === -1) {
          setUserInput(messageToShowTemp[messageToShowTemp.length - 1].text);
        } else {
          setUserInput(messageToShowTemp[textIndex - 1].text);
        }
      }
    }
    if (e.key === "ArrowDown") {
      if (userInput) {
        const textIndex = messageToShowTemp.findIndex(
          (message) => message.text === userInput
        );
        console.log("textIndex", textIndex);
        if (textIndex !== -1) {
          if (textIndex + 1 === messageToShowTemp.length) {
            setUserInput("");
          } else {
            setUserInput(messageToShowTemp[textIndex + 1].text);
          }
        }
      }
    }
  };

  return (
    <div className="Demo">
      {change ? (
        <SaarthiInternal
          setMessages={setMessages}
          messages={messages}
          userInput={userInput}
          setUserInput={setUserInput}
          setChange={setChange}
          loading={loading}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
      ) : (
        <>
          <section className="top-liner">
            <h1>
              <span>Introducing SaarthiGPT: </span>
              <div className="text-slide">
                <span>The All Knowing AI Guide to Higher Education!!</span>
                <span>The All Knowing AI Guide to Higher Education!!</span>
              </div>
            </h1>
          </section>
          <section class="top-section">
            <h2>
              <span>Hi there!</span>
              <br />
              How can I help you today?
            </h2>
          </section>
          <section class="slider-wrap">
            <Carousel
              responsive={responsive}
              // infinite={true}
              autoPlaySpeed={1000}
              customTransition="all 1s"
              transitionDuration={500}
              itemClass="carousel-item-padding-40-px"
            >
              {saarthi_constants.map((query, index) => {
                return (
                  <div
                    class="slide-item"
                    key={index}
                    onClick={() => onSelectPreDefinedQuery(query.text)}
                  >
                    <div class="slider-box">
                      <span class="slider-text">{query.text}</span>
                      <span class="slider-circle-icon">
                        <img
                          src={query.img}
                          alt="icon"
                          width="auto"
                          hight="auto"
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </section>
        </>
      )}

      <section class="query-section">
        <input
          type="text"
          name="query"
          placeholder="Type your query here..."
          class="query-input-style"
          autoComplete="off"
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
          style={{
            backgroundColor: 'rgba(251, 251, 252, 0.93)',
            color: '#050038'
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" && userInput) {
              sendMessage(e.target.value);
              setChange(true);
            }
            handleKeyChange(e);
          }}
        />
        <button
          class="submit-btn"
          onClick={() => {
            if (userInput) {
              sendMessage(userInput);
              setChange(true);
            }
          }}
        >
          <img src={img8} width="32" height="32" alt="button" id={`${userInput === '' ? 'submit-icon' : ''}`}/>
        </button>
      </section>
    </div>
  );
};

export default Saarthi;
