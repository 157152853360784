import React from "react";

const Intructions = () => {
  return (
    <div className="mb-3">
      <button
        type="button"
        class="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <i className="fa-solid fa-book-open"></i>
        <span className="ms-2">Instructions</span>
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Instructions For PDF/Document Search
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul>
                <li>
                  This System is used to query on 1 or multiple Document to get
                  Data/Informations related to the Document.
                </li>
                <li>
                  After Selecting Database, you can see all the pdf/documents in the dropdown.
                </li>
                <li>
                  All PDF's/Documents name has year mentioned with them. You can
                  select 1 or multiple PDF/Document to query.
                </li>
                <li>
                  Sample Queries are Listed below:
                  <li>DOC: Lovely Professional University (2024)</li>
                  <ul>
                    <li>
                      What is the contract value in the agreement between
                      girnarsoft and LPU?
                    </li>
                    <li>
                      What is the Variable service fee in the agreement between
                      girnarsoft and LPU?
                    </li>
                  </ul>
                  <li>
                    DOC : Lovely Professional University (2024) and ABBS (2024)
                  </li>
                  <ul>
                    <li>
                      Compare the contract value in the agreement of girnarsoft
                      with LPU and ABBS?
                    </li>
                    <li>
                      Compare the variable service fee in the agreement of
                      girnarsoft with LPU and ABBS?
                    </li>
                    <li>
                      Compare the payment terms in the agreement of girnarsoft
                      with LPU and ABBS in html tabuler format?
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intructions;
