import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Loader from "../Loader";
import parse from "html-react-parser";
import Intructions from "../Intructions";

const QueryCollegeBrochure = ({ database, setDatabase }) => {
  const [documents, setDocuments] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [queryResult, setQueryResult] = useState("");
  const [pdfLinks, setPdfLinks] = useState([]);
  const [finalIdToSend, setFinalIdToSend] = useState([])

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const findPdf_link = async (selectedDocs) => {
    const links = [];

    for (let doc of selectedDocs) {
      const matchingDoc = data.filter((d) => d.institute_id === doc.value);
      matchingDoc.forEach((mdoc,index) => {
        links.push({
          id: mdoc.id,
          link: mdoc.institute_brochure_url
        });
      })
    }

    setPdfLinks(links);
  };

  const onChangeOptions = (data) => {
    findPdf_link(data);
    setFinalIdToSend([])
  };

  const getData = async (db) => {
    try {
      setIsLoading(true);
      const params = {
        database: db,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_PDF_SERVER_URL}/get-pdf`,
        params
      );
      if (res && res.data) {
        const pdfArray = res.data;
        const options = [];
        const tempData = [];
        for (let doc of pdfArray) {
          tempData.push({
            ...doc,
          });
          if (db === "finance") {
            options.push({
              value: doc.id,
              label: `${doc.name} (${doc.year})`,
            });
          } else {
            const found = options.some(
              (option) => option.value === doc.institute_id
            );
            if (!found) {
              options.push({
                value: doc.institute_id,
                label: `${doc.name}`,
              });
            }
          }
        }
        setData(tempData);
        setDocuments(options);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const submitQuery = async () => {
    setLoading(true);

    try {
      if (!query || finalIdToSend.length === 0 || !database) {
        alert("Please Provide all Data");
        return;
      }

      const ids = [];
      for (let doc of finalIdToSend) {
        ids.push(doc.id);
      }

      const params = {
        ids,
        query,
        database,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_PDF_SERVER_URL}/query-pdf`,
        params
      );
      setQueryResult(res.data);
    } catch (e) {
      console.log("Error while querying pdf");
    } finally {
      setLoading(false);
    }
  };

  const checkS3LinkInLocalStorage = () => {
    const s3_links = localStorage.getItem("s3_links");
    if (!s3_links) {
      localStorage.setItem("s3_links", JSON.stringify({}));
    }
  };

  useEffect(() => {
    checkS3LinkInLocalStorage();
    getData(database);
  }, []);

  const addPdfForQuery = (checked,id,link) => {

    let tempData = [...finalIdToSend]
    
    if(checked) {
      tempData.push({
        id : id,
        link : link
      })
      setFinalIdToSend(tempData)
    }else{
      tempData = tempData.filter(data => data.id !== id)
      setFinalIdToSend(tempData)
    }

  }

  return (
    <div>
      <div className="container">
        <div className="row mt-2">
          <div className="col-md-3">
            <button onClick={() => setDatabase("")} className="btn btn-success">
              <i class="fa-solid fa-backward-step"></i> Go Back
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <Intructions />
          <>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-dark text-white">
                  Select Institute PDF
                </div>
                <div className="card-body">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="mt-2">
                      <Select
                        options={documents}
                        isMulti
                        onChange={onChangeOptions}
                        placeholder="Search institute name to query .."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-dark text-white">
                All Docs Web Links
              </div>
              <div
                className="card-body"
                style={{
                  maxHeight: "282px",
                  overflow: "auto",
                }}
              >
                {pdfLinks && (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div class="list-group" style={{ fontSize: "13px" }}>
                          {pdfLinks.map((link, index) => {
                            return (
                              <div className="row">
                                <div className="col-md-1">
                                  <input
                                    class="form-check-input"
                                    style={{
                                      padding: "15px",
                                      marginTop: "2px",
                                    }}
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    onChange={(e) => addPdfForQuery(e.target.checked, link.id, link.link)}
                                    // checked={true}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <a
                                    href={link.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    class="list-group-item"
                                  >
                                    {/* Document {index + 1} */}
                                    {link.link}
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-dark text-white">
                Selected Docs Web Links
              </div>
              <div
                className="card-body"
                style={{
                  maxHeight: "282px",
                  overflow: "auto",
                }}
              >
                {
                  finalIdToSend.length > 0 && <div className="list-group" style={{ fontSize: "13px" }}>
                    {finalIdToSend.map((data, index) => {
                     return <a className="list-group-item" target="_blank"
                     rel="noreferrer"  href={data.link} >{data.link}</a>
                  })}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-dark text-white">PDF Query</div>
              <div className="card-body">
                <div className="mt-3 form-group">
                  <textarea
                    placeholder="Enter your Query..."
                    name=""
                    id=""
                    cols="30"
                    className="form-control"
                    rows="3"
                    value={query}
                    onChange={onChangeQuery}
                  ></textarea>
                </div>
                <button className="btn btn-success mt-3" onClick={submitQuery}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-dark text-white">
                Query Response
              </div>
              <div className="card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {queryResult.includes("<tab") ? (
                      parse(queryResult.replace("```", "").replace("html", ""))
                    ) : (
                      <textarea
                        name=""
                        placeholder="Query Results"
                        value={queryResult}
                        id=""
                        cols="30"
                        rows="8"
                        className="form-control"
                      ></textarea>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryCollegeBrochure;
