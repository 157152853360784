import img4 from "../assets/images/box-icon1.svg";
import img5 from "../assets/images/box-icon2.svg";
import img6 from "../assets/images/box-icon3.svg";
import img7 from "../assets/images/box-icon4.svg";

export const saarthi_constants = [
  {
    text: "Which college will I get with a 79 percentile in CAT?",
    img: img4,
  },
  {
    text: "Compare Fees for BBA at LPU and CU.",
    img: img5,
  },
  {
    text: "My budget is 60,000 rupees per year. Which BCA college can I get?",
    img: img6,
  },
  {
    text: "Can I get direct admission in B.Tech CSE?",
    img: img7,
  },
];
