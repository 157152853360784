import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import parse from "html-react-parser";
import Loader from "../Loader";
import Intructions from "../Intructions";

const QueryCollegeMou = ({ database, setDatabase }) => {
  const [documents, setDocuments] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [pdfId, setPdfId] = useState([]);
  const [queryResult, setQueryResult] = useState("");
  const [pdfLinks, setPdfLinks] = useState([]);

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const fetchPresignedUrl = async (s3_path, s3_links, id) => {
    let presigned_url = "";
    const params = {
      s3_path,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_PDF_SERVER_URL}/make-presigned-s3-url`,
      params
    );
    if (res && res.data) {
      presigned_url = res.data;
      s3_links[id] = {
        presigned_url: res.data,
        updatedOn: Date.now(),
      };
      localStorage.setItem("s3_links", JSON.stringify(s3_links));
    } else {
      console.log("Did not get presigned URL");
    }
    return presigned_url;
  };

  const checkS3LinkExpiry = (givenTimestamp) => {
    const currentTimestamp = Date.now();
    const differenceMs = currentTimestamp - givenTimestamp;
    const differenceSec = differenceMs / 1000;
    return differenceSec > 86400;
  };

  const getPresignedUrl = async (s3_path, id) => {
    try {
      let presigned_url = "";
      let s3_links = localStorage.getItem("s3_links")
        ? JSON.parse(localStorage.getItem("s3_links"))
        : "";
      if (s3_links) {
        if (s3_links[id]) {
          const isUrlExpired = checkS3LinkExpiry(s3_links[id].updatedOn);
          if (!isUrlExpired) {
            presigned_url = s3_links[id].presigned_url;
          } else {
            presigned_url = fetchPresignedUrl(s3_path, s3_links, id);
          }
        } else {
          presigned_url = fetchPresignedUrl(s3_path, s3_links, id);
        }
      }
      return presigned_url;
    } catch (e) {
      console.log("Error while getting presigned URL", e);
    }
  };

  const findPdf_link = async (selectedDocs) => {
    const links = [];

    for (let doc of selectedDocs) {
      const matchingDoc = data.find((d) => d.id === doc.value);
      const presigned_url = await getPresignedUrl(
        matchingDoc.s3_path,
        matchingDoc.id
      );
      links.push(presigned_url);
    }

    setPdfLinks(links);
  };

  const onChangeOptions = (data) => {
    setPdfId(data);
    findPdf_link(data);
  };

  const getData = async (db) => {
    try {
      setIsLoading(true);
      const params = {
        database: db,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_PDF_SERVER_URL}/get-pdf`,
        params
      );
      if (res && res.data) {
        const pdfArray = res.data;
        const options = [];
        const tempData = [];
        for (let doc of pdfArray) {
          tempData.push({
            ...doc,
          });

          options.push({
            value: doc.id,
            label: `${doc.name} (${doc.year})`,
          });
        }
        setData(tempData);
        setDocuments(options);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const submitQuery = async () => {
    setLoading(true);

    try {
      if (!query || pdfId.length === 0 || !database) {
        alert("Please Provide all Data");
        return;
      }

      const ids = [];
      for (let doc of pdfId) {
        ids.push(doc.value);
      }

      const params = {
        ids,
        query,
        database,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_PDF_SERVER_URL}/query-pdf`,
        params
      );
      setQueryResult(res.data);
    } catch (e) {
      console.log("Error while querying pdf");
    } finally {
      setLoading(false);
    }
  };

  const checkS3LinkInLocalStorage = () => {
    const s3_links = localStorage.getItem("s3_links");
    if (!s3_links) {
      localStorage.setItem("s3_links", JSON.stringify({}));
    }
  };

  useEffect(() => {
    checkS3LinkInLocalStorage();
    getData(database);
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row mt-2">
          <div className="col-md-3">
            <button onClick={() => setDatabase("")} className="btn btn-success">
              <i class="fa-solid fa-backward-step"></i> Go Back
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <Intructions />
          <>
            <div className="col-md-7">
              <div className="card">
                <div className="card-header bg-dark text-white">Search PDF</div>
                <div className="card-body">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="mt-2">
                      <Select
                        options={documents}
                        isMulti
                        onChange={onChangeOptions}
                        placeholder="Search institute name to query .."
                      />
                      <div className="mt-3 form-group">
                        <textarea
                          placeholder="Enter your Query..."
                          name=""
                          id=""
                          cols="30"
                          className="form-control"
                          rows="4"
                          value={query}
                          onChange={onChangeQuery}
                        ></textarea>
                      </div>
                      <button
                        className="btn btn-success mt-3"
                        onClick={submitQuery}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <div className="card-header bg-dark text-white">
                  Select Docs S3/Web Links
                </div>
                <div
                  className="card-body"
                  style={{
                    maxHeight: "282px",
                    overflow: "auto",
                  }}
                >
                  {pdfLinks && (
                    <div class="list-group" style={{ fontSize: "13px" }}>
                      {pdfLinks.map((link) => {
                        return (
                          <a
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            class="list-group-item"
                          >
                            {link.split("?")[0]}
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-dark text-white">
                Query Response
              </div>
              <div className="card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {queryResult.includes("<tab") ? (
                      parse(queryResult.replace("```", "").replace("html", ""))
                    ) : (
                      <textarea
                        name=""
                        placeholder="Query Results"
                        value={queryResult}
                        id=""
                        cols="30"
                        rows="8"
                        className="form-control"
                      ></textarea>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryCollegeMou;
