import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import img1 from "../assets/images/cld-logo.svg";
import img2 from "../assets/images/phone-icon.svg";
import img3 from "../assets/images/mailicon.svg";

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/login");
  };

  const isEmpty = (value) => {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  };

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (isEmpty(parsedUser.email) === true) {
        sessionStorage.removeItem("user");
        setIsLoggedIn(false);
        navigate("/login");
      } else {
        setUserEmail(parsedUser.email);
      }
    }
  }, []);

  return (
    <header>
      <div class="header-inner">
        <aside class="header-left">
          <a href="/">
            <img src={img1} alt="logo" width="138" height="52" />
          </a>
        </aside>
        <aside class="header-right">
          <div className="header-right-div" style={{
            display: 'flex',
          }}>
            <img src={img2} alt="phone" width="16" height="17" />
            <a href="tel:1800-572-9877">1800-572-9877</a>
          </div>
          {isLoggedIn && (
            <div className="header-right-div" style={{
              display: 'flex',
            }}>
              <img src={img3} alt="email" width="16" height="17"></img>
              <a href="mailto:hello@collegedekho.com">{userEmail}</a>
            </div>
          )}
          {isLoggedIn && (
            <div class="dropdown">
              <div
                class="logout-btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa-solid fa-user"></i>
              </div>
              <ul class="dropdown-menu dropdown-menu-dark mt-2">
                <li onClick={logout}>
                  <span className="dropdown-item" href="">
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          )}
        </aside>
      </div>
    </header>
  );
};

export default Navbar;
