import React from 'react'

const Loader = () => {
  return (
    <div className='loader'>
        <img src="https://miro.medium.com/v2/resize:fit:882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" alt="Loader" />
    </div>
  )
}

export default Loader