import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
    return (

        <div className='container'>

            <div>
                <h1>404 Page Not Found</h1>
                <p>Oops! The page you are looking for does not exist.</p>
                <p><Link to="/">Go back to home</Link></p>
            </div>
        </div>
    );
}

export default NotFound;
