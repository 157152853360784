import React, { useEffect, useState } from "react";
import axios from "axios";
import ChatBox from "./ChatBox";
import Select from "react-select";
import { fetchHeaders } from "../../utils/TokenUtils";

const FetchQueryOutput = ({ user }) => {
  const [data, setData] = useState({
    institute_id: null,
    llm: null,
    sys_prompt:
      "You are a college information assistant.Your task is to fetch and provide the details of the college that the user is interested in. Please provide the information based on the context provided. If you do not have the information return 'I DO NOT HAVE INFORMATION'. Also, do not provide explanations or notes.",
    prompt: null,
  });

  const [institutes, setInstitutes] = useState([]);
  const [chunks_found, setChunks_found] = useState("");
  const [isAdmin, setIsAdmin] = useState(false)

  const onFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectChange = (data) => {
    setData({
      ...data,
      institute_id: data.value,
    });
  };

  const fetchAllInstitutes = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/get-institutes`,
        {
          headers: fetchHeaders(),
        }
      );
      if (res && res.data) {
        let options = [];
        for (const inst of res.data) {
          options.push({
            value: inst.cld_id,
            label: inst.name,
          });
        }
        setInstitutes(options);
      }
    } catch (e) {
      console.log("Error while fetching all institutes", e);
    }
  };

  useEffect(() => {
    const user_details = JSON.parse(user)
    setIsAdmin(user_details.is_admin)
    if(user_details.is_admin){
      fetchAllInstitutes();
    }
  }, []);

  const shortenString = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
  };

  if (!isAdmin) {
    return <>
      <div className="container">
      <div className="row mt-3">
          <h1 className="h2">
          <i class="fa-solid fa-triangle-exclamation me-3" style={{
            color: '#D2042D'
          }}></i>
            <strong style={{
              color: '#D2042D'
            }}>Not Authorized!</strong>
          </h1>
      </div>
    </div>
    </>
  }


  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-4">
            <div className="form-group">
              <Select
                options={institutes}
                placeholder="Select Institute"
                onChange={onSelectChange}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <select
                name="llm"
                onChange={(e) => onFormChange(e)}
                className="form-control"
                value={data.llm}
              >
                <option value="">Enter LLM Model ...</option>
                <option value="3.5t">GPT 3.5 Turbo</option>
                <option value="mixtral7b">Mixtral 7b</option>
                <option value="mixtral22b">Mixtral 22b</option>
                <option value="llama">llama</option>
                <option value="4o-mini">GPT 4o Mini</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="form-group">
            <label htmlFor="" className="mb-1">
              System prompt:-{" "}
            </label>
            <textarea
              name="sys_prompt"
              onChange={(e) => onFormChange(e)}
              className="form-control"
              value={data.sys_prompt}
              rows={3}
            ></textarea>
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-6">
            <ChatBox
              data={data}
              setData={setData}
              setChunks_found={setChunks_found}
            />
          </div>
          <div
            className="col-md-6"
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            <div class="accordion accordion-flush" id="accordionFlushExample">
              {chunks_found.length > 0 &&
                chunks_found.map((chunk, index) => {
                  return (
                    <div class="accordion-item" key={index}>
                      <h2 class="accordion-header">
                        <div>
                          <a
                            className="chunk_link"
                            href={chunk.chunk}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {shortenString(chunk.chunk, 63)}
                          </a>
                          <span className="ms-4 me-3 chunk_score">
                            {chunk.score.toFixed(2)}
                          </span>{" "}
                          {"\n"}
                        </div>
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collape${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collape${index}`}
                        ></button>
                      </h2>
                      <div
                        id={`flush-collape${index}`}
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          Chunk Id :- {chunk.id} {"\n"} {"\n"}
                          <div className="mt-2">Content :- {chunk.content}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FetchQueryOutput;
