import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";

import { fetchHeaders } from "../../utils/TokenUtils";

import "react-toastify/dist/ReactToastify.css";

const InstituteUpdate = ({ user }) => {
  const [institutes, setInstitutes] = useState([]);
  const [options, setOptions] = useState([]);
  const [instId, setInstId] = useState("");
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchAllInstitutes = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/get-institutes`,
        {
          headers: fetchHeaders(),
        }
      );
      if (res && res.data) {
        let options = [];
        for (const inst of res.data) {
          options.push({
            value: inst.cld_id,
            label: inst.name,
          });
        }
        setOptions(options);
        setInstitutes(res.data);
      }
    } catch (e) {
      console.log("Error while fetching all institutes", e);
    }
  };

  useEffect(() => {
    const user_details = JSON.parse(user)
    setIsAdmin(user_details.is_admin)
    if(user_details.is_admin){
      fetchAllInstitutes();
    }
  }, []);

  const onSelectChange = (data) => {
    setInstId(data.value);
    const fetched_institutes = [...institutes];

    const selected_institute = fetched_institutes.find(
      (inst) => inst.cld_id == data.value
    );

    setData({
      inst_id: Number(data.value),
      downloaded: selected_institute.downloaded,
      embedding_generated: selected_institute.embedding_generated,
    });
  };

  const resetForm = () => {
    const fetched_institutes = [...institutes];

    const selected_institute = fetched_institutes.find(
      (inst) => inst.cld_id == data.inst_id
    );

    setData({
      inst_id: Number(data.inst_id),
      downloaded: selected_institute.downloaded,
      embedding_generated: selected_institute.embedding_generated,
    });
  };

  const updateFields = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: true,
      icon: false,
    });
  };

  const showErrorMessage = (message) => {
    toast.error(message, {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: true,
      icon: false,
    });
  };

  const updateInstitute = async () => {
    try {
      const params = {
        institute_id: Number(instId),
        downloaded: data.downloaded,
        embedding_generated: data.embedding_generated,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/update-institute-status`,
        params,
        {
          headers: fetchHeaders(),
        }
      );

      if (res && res.data) {
        let temp_institutes = [...institutes];
        temp_institutes = temp_institutes.map((inst) => {
          if (inst.cld_id == instId) {
            inst.downloaded =
              data.downloaded == "true" || data.downloaded == true
                ? true
                : false;
            inst.embedding_generated =
              data.embedding_generated == "true" ||
              data.embedding_generated == true
                ? true
                : false;
          }
          return inst;
        });

        console.log("temp_institutes", temp_institutes);

        setInstitutes(temp_institutes);
        showSuccessMessage("Successfully Updated Institute!");
      }
    } catch (e) {
      console.log("Error while Updating institutes", e);
      showErrorMessage(e);
    }
  };

  if (!isAdmin) {
    return <>
      <div className="container">
      <div className="row mt-3">
          <h1 className="h2">
          <i class="fa-solid fa-triangle-exclamation me-3" style={{
            color: '#D2042D'
          }}></i>
            <strong style={{
              color: '#D2042D'
            }}>Not Authorized!</strong>
          </h1>
      </div>
    </div>
    </>
  }


  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-dark text-white">
                <h5>Update Institute Table</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <Select
                    options={options}
                    placeholder="Select Institute"
                    onChange={onSelectChange}
                  />
                </div>

                {Object.keys(data).length > 0 && (
                  <>
                    <div className="form-group mb-3 mt-3">
                      <label htmlFor="" className="mb-2">
                        Institute Downloaded :
                      </label>
                      <select
                        name="downloaded"
                        value={data.downloaded}
                        className="form-control"
                        onChange={updateFields}
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="" className="mb-2">
                        Institute Embedding Generated :
                      </label>
                      <select
                        name="embedding_generated"
                        value={data.embedding_generated}
                        className="form-control"
                        onChange={updateFields}
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>
                    <button
                      className="btn btn-success"
                      onClick={updateInstitute}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-warning ms-2"
                      onClick={resetForm}
                    >
                      Reset
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstituteUpdate;
