import React from 'react'

const Loading = () => {
  return (
    <div className='Loading'>
        <img width="40" height="40" src="https://bcacollegesearch.com/wp-content/themes/bcaindiatemplatetwo/images/loading.gif" alt="Loader" />
    </div>
  )
}

export default Loading