import React, { useEffect, useState } from "react";
import axios from "axios";
import img1 from "../assets/images/cld-logo.svg";
import img2 from "../assets/images/phone-icon.svg";
import img3 from "../assets/images/mailicon.svg";

import "./Login.css";

const Login = ({ setIsLoggedIn }) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);

  const login = async () => {
    try {
      const { email, password } = user;
      setIsLoading(true);
      if (email && password && terms && !loading) {
        const params = {
          email,
          password,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_PDF_SERVER_URL}/login`,
          params
        );

        if (res && res.data) {
          const response = res.data;

          const userInfo = {
            email: response.data,
            is_admin : response.is_admin
          };

          const jwt_token = response.jwt_token;

          if (response.code === 400) {
            setErrorMessage(response.message);
          } else {
            sessionStorage.setItem("user", JSON.stringify(userInfo));
            sessionStorage.setItem("token", jwt_token);

            setIsLoggedIn(true);
            window.location.href = "/";
          }
        } else {
          setErrorMessage("Got No Response");
        }
      } else {
        if (!terms) {
          setErrorMessage("Please Accept the Terms and Conditions to Log In!");
        } else {
          setErrorMessage("Please enter all required information!");
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(`Error while loggin in Saarthi Dashboard: ${error}`);
      setIsLoading(false);
    }
  };

  const onChangeForm = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <div class="header-inner">
            <aside class="header-left">
              <aside href="/">
                <img src={img1} alt="logo" width="138" height="52" />
              </aside>
            </aside>
            <aside class="header-right">
              <div
                className="header-right-div"
                style={{
                  display: "flex",
                }}
              >
                <img src={img2} alt="phone" width="16" height="17" />
                <a href="tel:1800-572-9877">1800-572-9877</a>
              </div>
            </aside>
          </div>
        </div>
      </header>

      <div className="login-form-container">
        <div className="login-form">
          <div className="login-form-inner">
            <p className="login-text-primary">Login</p>
            <p className="login-text-secondary">Please sign in to Continue.</p>

            <div className="login-input-boxes mt-2">
              <div className="login-input-box">
                <input
                  name="email"
                  onChange={onChangeForm}
                  type="text"
                  placeholder="Email"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    paddingLeft: "16px",
                  }}
                />
              </div>
              <div className="login-input-box mt-3">
                <input
                  name="password"
                  onChange={onChangeForm}
                  type="password"
                  placeholder="Password"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    paddingLeft: "16px",
                  }}
                />
              </div>
            </div>

            <div className="login-terms mt-4 pt-2">
              <div className="login-terms-check">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) => setTerms(e.target.checked)}
                />
              </div>
              <div>
                <p className="login-terms-check-text-1">
                  By proceeding ahead you expressly agree to the CollegeDekho
                </p>
                <p className="login-terms-check-text-2">
                  <a className="login-terms-check-text-special" href="https://www.collegedekho.com/terms-and-conditions-refund-policy" target="_blank" rel="noreferrer">
                    Terms & Conditions{" "}
                  </a>{" "}
                  and{" "}
                  <a className="login-terms-check-text-special" href="https://www.collegedekho.com/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy.
                  </a>
                </p>
              </div>
            </div>
            <p className="error-msg-div">{errorMessage}</p>
            <button
              className="login-btn mt-3"
              onClick={login}
              style={{
                backgroundColor: "#3F53D9",
                color: "#fff",
              }}
            >
              Login Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
