export const fetchHeaders = () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
  } else {
    console.log("Token not found");
    return {};
  }
};
