import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import AddPdf from "./components/PDF/AddPdf";
import "./App.css";
import Login from "./components/Login";
import Home from "./components/Home";
import DashboardMvp from "./components/dashboard_mvp/DashboardMvp.jsx";
import DataCollectionListing from "./components/dashboard_mvp/DataCollectionListing.jsx";
import CollegesPdfurl from "./components/dashboard_mvp/CollegesPdfurl.jsx";
import NotFound from "./components/NotFound";
import FetchQueryOutput from "./components/RAG/FetchQueryOutput.jsx";
import Demo from "./Demo.jsx";
import PromptCRUD from "./components/RAG/PromptCRUD.jsx";
import Saarthi from "./components/saarthi/Saarthi.jsx";
import BotpressSaarthi from './components/botpress/BotpressSaarthi.jsx'
import InstituteUpdate from "./components/RAG/InstituteUpdate.jsx";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkIfLoggedIn = () => {
    const user = sessionStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const user = sessionStorage.getItem("user");

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  if (!isLoggedIn && !user)
    return (
      <>
        <Login setIsLoggedIn={setIsLoggedIn} />
      </>
    );

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
        <Route path="/" element={<Saarthi gpt_4o_mini={true}/>} />
        {/* <Route path="/add-pdf" element={<AddPdf />} />
        <Route path="/DashboardMvp" element={<DashboardMvp />} />
        <Route path="/DataCollectionList" element={<DataCollectionListing />} />
        <Route path="/SearchTaggedPdfandUrls" element={<CollegesPdfurl />} /> */}
        {/* <Route path='/saarthi-4o-mini' element={<Saarthi gpt_4o_mini={true}/>}/> */}
        {/* <Route path="/botpress-saarthi" element={<BotpressSaarthi/>}/> */}
        
        
        <Route path="/dashboard" element={<Home user={user}/>} />
        <Route path="/rag-dashboard" element={<FetchQueryOutput user={user}/>} />
        <Route path="/prompt-crud" element={<PromptCRUD user={user}/>} />
        <Route path='/update-institute' element={<InstituteUpdate user={user}/>}/>

        <Route path="*" element={<NotFound />} />
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
